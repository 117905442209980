<template>
  <div>
    <SideBarFilter
      :filter="form"
      ref="sidebarBrand"
      placeholder="Brand"
      :hideStatusFilter="false"
      :hideSearchBar="true"
      title="Brand"
      :textSubmit="form?.id ? 'Update' : 'Create'"
      hideFilter
      handleSideBar
      @searchAll="handleSaveBrand"
      :isLoading="isLoading"
      @clearLoading="isLoading = false"
      @clearForm="clearForm"
    >
      <template v-slot:filter-option>
        <div class="my-3">
          <div class="list-lang">
            <div
              v-for="(item, index) in listLang"
              :key="index"
              class="box-lang"
              :class="selectedLang == item.languageId && 'active'"
              @click="selectedLang = item.languageId"
            >
              {{ item.nation }}
            </div>
          </div>
        </div>
        <div class="my-3">
          <InputText
            v-model="form.brandTrnaslation[indexLanguage].name"
            class="input"
            :textFloat="`BrandName`"
            placeholder="BrandName"
            type="text"
            name="name"
            isRequired
            :v="$v.form.brandTrnaslation.$each[indexLanguage].name"
            :isValidate="$v.form.brandTrnaslation.$error"
            :selectLang="language"
          />
        </div>
        <div class="my-3">
          <UploadFile
            textFloat="Images"
            placeholder="Please Choose File"
            format="image"
            name="file"
            text="* ขนาดรูปภาพที่แนะนำ 2000 * 900"
            accept="image/*"
            id="uploadfile"
            required="Please select image."
            isRequired
            :fileName="form.imgUrl"
            v-model="form.base64"
            @onFileChange="onFileChange($event)"
            :v="$v.form.imgUrl"
            :isValidate="$v.form.imgUrl.$error"
          />
        </div>
        <div
          class="render-image"
          :style="`background-image:url(${imageDisplay})`"
        ></div>
        <div class="my-3">
          <InputText
            v-model="form.sortOrder"
            class="input"
            textFloat="SortOrder"
            placeholder="SortOrder"
            type="number"
            name="SortOrder"
            :v="$v.form.sortOrder"
            :isValidate="$v.form.sortOrder.$error"
            isRequired
          />
        </div>
        <div class="my-3">
          <b-form-checkbox
            v-model="form.isActive"
            switch
            :value="1"
            :unchecked-value="0"
          >
            Active
          </b-form-checkbox>
        </div>
      </template>
    </SideBarFilter>
    <ModalAlertText :textModal="textModal" :arrModal="arrStatus" />
  </div>
</template>

<script>
import UploadFile from "@/components/inputs/UploadFile";
import ModalAlertText from "@/components/modal/ModalAlertText.vue";
import InputText from "@/components/inputs/InputText";
import { required, requiredIf, minValue } from "vuelidate/lib/validators";
export default {
  name: "BrandForm",
  components: {
    UploadFile,
    ModalAlertText,
    InputText,
  },
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    id: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      form: {
        id: 0,
        imgUrl: null,
        imgShowUrl: "",
        base64: null,
        sortOrder: 0,
        isActive: 0,
        brandTrnaslation: [
          {
            id: 0,
            name: null,
            languageId: 2,
          },
          {
            id: 0,
            name: null,
            languageId: 1,
          },
        ],
      },

      isLoading: false,

      listLang: [],

      selectedLang: 2,

      imageDisplay: "",

      // state modal
      textModal: "",
      arrStatus: [],
    };
  },
  validations() {
    return {
      form: {
        imgUrl: { required },
        brandTrnaslation: {
          $each: {
            name: {
              required: requiredIf(() => {
                const requiredLanguage = this.form.brandTrnaslation.find(
                  (x) => x.languageId === 2
                );
                return !requiredLanguage.name && this.selectedLang === 2;
              }),
            },
          },
        },
        sortOrder: { required, minValue: minValue(0) },
      },
    };
  },
  watch: {
    visible(val) {
      if (val === true) {
        this.getLanguageOption();
        this.getDefaultForm(this.id).then(() => {
          this.imageDisplay = this.id <= 0 ? "" : this.form.imgShowUrl;
          this.$refs.sidebarBrand.show();
        });
      } else {
        this.$refs.sidebarBrand.hide();
      }
    },
  },
  mounted() {},
  computed: {
    language() {
      return this.listLang.find((x) => x.languageId === this.selectedLang);
    },
    indexLanguage() {
      return this.form.brandTrnaslation.findIndex(
        (x) => x.languageId === this.selectedLang
      );
    },
  },
  methods: {
    async getDefaultForm(id) {
      const respone = await this.$services.brand.getBrandById(id);
      if (respone.result === 1) {
        this.form = await respone.detail;
      }
    },
    async getLanguageOption() {
      const getData = await this.$services.master.getLanguageOption();

      if (getData.result == 1) {
        this.listLang = getData.detail;
        this.selectedLang = this.listLang[0].languageId;
      }
    },
    async handleSaveBrand() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      this.isLoading = true;
      let respone = {};
      if (this.id <= 0) {
        respone = await this.$services.brand.insertBrand(this.form);
        let text = "";
      } else {
        respone = await this.$services.brand.updateBrand(this.form, this.id);
      }
      let text = "";
      if (respone.result === 1) {
        text = respone.detail.message;
      } else {
        text = respone.message;
      }
      this.isLoading = false;
      this.openModalAlertText(text);
      this.clearForm();
      this.$emit("fetchTable");
    },
    onFileChange(file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (result) => {
        this.form.imgUrl = file.name;
        this.imageDisplay = result.target.result;
        this.form.base64 = {
          base64: result.target.result,
        };
      };
    },
    clearForm() {
      this.$emit("hideForm");
      this.imageDisplay = "";
      this.$v.$reset();
      this.isLoading = false;
    },
    openModalAlertText(text) {
      this.textModal = text;
      this.$bvModal.show("modal-alert-text");
    },
  },
};
</script>

<style lang="scss" scoped>
.render-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 200px;
  border: 1px dashed gray;
}
.list-lang {
  display: flex;
  justify-content: right;

  .box-lang {
    padding: 5px 10px;
    margin-right: 5px;
    border: 1px solid black;
    cursor: pointer;

    &.active {
      background: var(--secondary-color);
      color: white;
    }
  }
}
</style>
